export const useErrorsStore = defineStore("errors", {
  state: () => {
    return {
      displayModal: false,
      modalTitle: "",
      modalText: "",
      error: {},
    };
  },

  getters: {},

  actions: {
    showModal(title: string, text: string, error?: Error): void {
      this.modalTitle = title;
      this.modalText = text;
      if (error != undefined) {
        this.error = error;
      }
      this.displayModal = true;
    },
    closeModal(): void {
      this.displayModal = false;
      this.modalTitle = "title";
      this.modalText = "";
      this.error = {};
    },
    clearBody(): void {
      if (document.body.classList.contains("modal-open")) {
        setTimeout(() => {
          document.body.className = document.body.className.replace(
            "modal-open",
            ""
          );
        }, 600);
        console.log("modal-open cleared");
      }
      console.log("clearBody run");
    },
  },
});
