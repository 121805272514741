import { FetchOptions } from "ofetch/dist/node";

export default async (url: string, opts: FetchOptions = {}): Promise<any> => {
  const { token } = useAuth();

  const config = useRuntimeConfig();

  // @ts-ignore
  return $fetch(url, {
    ...opts,
    baseURL: config.public.apiHost + "api",
    headers: {
      Authorization: token.value || "",
    },
  });
};
